*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    background-color: #080710;
}
.background{
    width: 470px;
    height: 520px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 250px;
    width: 250px;
    position: absolute;
    border-radius: 50%;
}
.shape:first-child{
    /* background: linear-gradient(
        #9b22ea,
        #bf23f6
    ); */
    background: linear-gradient(90deg, rgba(255,34,232,1) 35%, rgba(123,0,255,1) 100%);
    left: -180px;
    top: 40px;
}
.shape{
    /* background: linear-gradient(
        to right,
        #ff512f,
        #f09819
    ); */
    background: linear-gradient(90deg, rgba(255,34,232,1) 35%, rgba(123,0,255,1) 100%);
    right: -180px;
    bottom: -20px;
}
.form{
    height: 350px;
    width: 650px;
    background-color: rgba(255,255,255,0.07);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}
form *{
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
.form h2{
    font-size: 40px;
    font-weight: bold;
    line-height: 42px;
}

.form p{
    padding-top: 7%;
}

.form h3 span{
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: #e5e5e5;
}

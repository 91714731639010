.homepage {
	font-family: sans-serif;
	text-align: center;
}

#img1 {
	width: 42.5px;
	height: 45px;
	top: 10px;
	margin-left: 15px;
	
}
#img2 {
	width: 300px;
	height: 300px;
	position: fixed;
	left: 400px;
	top: 200px;
}
#img3 {
	position: fixed;
	left: 1150px;
	top: 120px;
}
#body1 {
	background-color: black;
}
#apple {
	position: fixed;
	top: 260px;
	left: 720px;
	font-size: 40px;
	color: aliceblue;
}
#dev {
	position: fixed;
	top: 300px;
	left: 720px;
	font-size: 40px;
	color: aliceblue;
}
#grp {
	position: fixed;
	top: 340px;
	left: 720px;
	font-size: 40px;
	color: aliceblue;
}
.rectangle {
	display: inline-block;
	width: 100px;
	height: 100px;
	background: black;
	border-radius: 20px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	background-color: black;
	color: white;
	font-family: "Poppins", sans-serif;
	padding-top: 90px;
}

header a {
	text-decoration: none;
}

header {
	padding: 0 20px;
	background-color: #1d1f1d;
	height: 50px;
	display: flex;
	justify-content: space-between;
}

#brand {
	font-weight: bold;
	font-size: 18px;
	align-items: center;
}

#brand a {
	color: white;
}

ul {
	list-style: none;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

ul a {
	color: white;
}

ul li {
	padding: 5px;
	margin-left: 10px;
}

ul li:hover {
	transform: scale(1.1);
	transition: 0.3s;
}

#hamburger-icon {
	margin: auto 0;
	display: none;
	cursor: pointer;
}

#hamburger-icon div {
	width: 35px;
	height: 3px;
	background-color: white;
	margin: 6px 0;
	transition: 0.4s;
}

.open .bar1 {
	-webkit-transform: rotate(-45deg) translate(-6px, 6px);
	transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
	opacity: 0;
}

.open .bar3 {
	-webkit-transform: rotate(45deg) translate(-6px, -8px);
	transform: rotate(45deg) translate(-6px, -8px);
}

.open .mobile-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.mobile-menu {
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	height: calc(100vh - 50px);
	width: 100%;
}

.mobile-menu li {
	margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
	header nav {
		display: none;
	}

	#hamburger-icon {
		display: block;
	}
}
.title {
	color: aliceblue;
}
nav {
	/* background-color: #1d1f1d; */
	background-color: black;
	border: 2px solid black;
	position: absolute;
	top: 25px;
	height:50px;
	width: 88%;
	left:130px;
	border-radius: 25px;
	border-bottom: 2px solid white;
}
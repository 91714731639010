*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    background-color: #080710;
}
.background{
    width: 430px;
    height: 520px;
    /* width:50%;
    height: 60%; */
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape2{
    height: 500px;
    width: 500px;
    position: absolute;
    border-radius: 50%;
}
.shape2:first-child{
    background: linear-gradient(
        #9b22ea,
        #bf23f6
    );
    left: -350px;
    top: 200px;
}

form{
    margin-top: 150px;
    height: 600px;
    width: 650px;
    background-color: rgba(255,255,255,0.07);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}
form *{
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
form h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
}
form h3 span{
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: #e5e5e5;
}

input{
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255,255,255,0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}
input::placeholder{
    color: white;
}
button{
    margin-top: 30px;
    width: 80%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}